<template>
  <c-box
    mb="5"
    p="6"
    bg="#fff"
    borderRadius="8px"
    boxShadow="0 3px 6px rgba(186, 186, 186, 0.16)"
    class="post_div"
  >
    <c-flex align="start">
      <c-popover
        v-if="isCompanyAdmin"
        :placement="'bottom'"
        v-slot="{ onClose }"
      >
        <c-popover-trigger>
          <c-flex align="center" mr="6" cursor="pointer">
            <c-image
              v-if="activeCompany.logo || user.profilePhoto"
              :name="user.firstname + ' ' + user.lastname"
              w="45px"
              rounded="lg"
              h="45px"
              objectFit="cover"
              :src="
                postAs
                  ? activeCompany.logo ?? placeHolderImage
                  : user.profilePhoto
              "
              alt="user-avatar"
            />
            <c-flex
              v-else
              justify="center"
              align="center"
              w="45px"
              h="45px"
              rounded="lg"
              :bg="getInitialsColor(user.firstname)"
            >
              <c-text fontSize="xl" fontWeight="500">{{
                getFirstLetterName(user.firstname, user.lastname)
              }}</c-text>
            </c-flex>
            <svg
              v-chakra="{
                fill: 'vc-orange.400',
                w: '14px',
                h: '14px',
              }"
            >
              <use href="@/assets/icons/chevron-down.svg#chevron"></use>
            </svg>
          </c-flex>
        </c-popover-trigger>
        <c-popover-content style="z-index: 100" width="max-content">
          <c-popover-body padding="0">
            <c-list>
              <c-list-item>
                <c-pseudo-box
                  alignItems="center"
                  px="5"
                  py="3"
                  display="flex"
                  cursor="pointer"
                  @click="
                    togglePostAs(activeCompany.id, onClose);
                    isCompany = true;
                  "
                  :_hover="{
                    bg: 'gray.300',
                  }"
                  v-if="!postAs"
                >
                  <c-text>Post as {{ activeCompany.name }}</c-text>
                </c-pseudo-box>
                <c-pseudo-box
                  alignItems="center"
                  px="5"
                  py="3"
                  display="flex"
                  cursor="pointer"
                  @click="
                    togglePostAs(null, onClose);
                    isCompany = false;
                  "
                  :_hover="{
                    bg: 'gray.300',
                  }"
                  v-else
                >
                  <c-text>
                    Post as {{ user.firstname }} {{ user.lastname }}
                  </c-text>
                </c-pseudo-box>
              </c-list-item>
            </c-list>
          </c-popover-body>
        </c-popover-content>
      </c-popover>
      <c-flex v-else align="center" mr="6">
        <c-image
          v-if="user.profilePhoto"
          :name="user.firstname + ' ' + user.lastname"
          w="45px"
          rounded="lg"
          h="45px"
          :src="postAs ? activeCompany.logo : user.profilePhoto"
          alt="user-avatar"
        />
        <c-flex
          justify="center"
          align="center"
          w="45px"
          h="45px"
          rounded="lg"
          :bg="getInitialsColor(user.firstname)"
        >
          <c-text fontSize="xl" fontWeight="500">{{
            getFirstLetterName(user.firstname, user.lastname)
          }}</c-text>
        </c-flex>
      </c-flex>
      <c-pseudo-box
        alignItems="center"
        bg="gray.200"
        h="50px"
        borderRadius="10px"
        px="7"
        flex="1"
        display="flex"
        cursor="pointer"
        @click="openPostModal"
        :_hover="{
          bg: 'gray.300',
        }"
      >
        <c-text fontSize="lg" color="gray.400" fontWeight="500">
          What is on your mind ?
        </c-text>
      </c-pseudo-box>

      <!--      <c-button-->
      <!--          h="50px"-->
      <!--          class="inline md:hidden"-->
      <!--          ml="10px"-->
      <!--          color="orange.500"-->
      <!--          bg="vc-orange.20"-->
      <!--          borderRadius="10px"-->
      <!--          @click.native="openPostModal"-->
      <!--      >-->
      <!--       Post-->
      <!--      </c-button>-->
    </c-flex>

    <c-flex style="flex-wrap: wrap" pt="6" justify="space-between">
      <c-flex align="center" mb="3">
        <c-button
          @click="
            openFileDialog();
            selectPostAddonType('media');
          "
        >
          <c-icon color="vc-info.500" name="images" size="24px" />
          <c-text ml="3">Media</c-text>
        </c-button>
      </c-flex>
      <c-flex mb="3" align="center">
        <c-button
          @click="
            openFileDialog();
            selectPostAddonType('document');
          "
        >
          <c-icon color="vc-green.500" name="link" size="24px" />
          <c-text ml="3">File</c-text>
        </c-button>
      </c-flex>
      <c-flex mb="3">
        <c-button
          @click="
            openFeelingDialog();
            selectPostAddonType('feeling');
          "
        >
          <c-icon color="vc-orange.500" name="smile" size="24px" />
          <c-text ml="3">Feeling</c-text>
        </c-button>
      </c-flex>
      <c-flex mb="3">
        <!--          <c-button @click="openCreateEventModal">-->
        <c-button
          @click="
            openPostModal();
            selectPostAddonType('event');
            openCreateEventModal();
          "
        >
          <c-icon color="vc-orange.500" name="calendar" size="24px" />
          <c-text ml="3">Event</c-text>
        </c-button>
      </c-flex>
    </c-flex>
    <tagged-users-modal
      v-if="showAllUsersModal"
      @closeAllUsersModal="showAllUsersModal = false"
    />
    <tagged-companies-modal
      v-if="showAllCompaniesModal"
      @closeAllCompaniesModal="showAllCompaniesModal = false"
    />
    <c-modal
      :is-centered="true"
      size="lg"
      :is-open="isPostModalOpen || openModal"
      :on-close="closePostModal"
      :closeOnOverlayClick="false"
      :useInert="false"
    >
      <c-modal-content ref="content" borderRadius="10px">
        <c-modal-header>
          <c-text fontSize="1xl" fontWeight="800">Create Post</c-text>
        </c-modal-header>
        <c-modal-close-button color="vc-orange" />
        <c-modal-body>
          <c-flex mb="10px" align="center" mr="6" cursor="pointer">
            <c-image
              v-if="activeCompany?.logo || user.profilePhoto"
              :name="user.firstname + ' ' + user.lastname"
              w="60px"
              rounded="lg"
              objectFit="cover"
              h="60px"
              :src="
                postAs
                  ? activeCompany.logo ?? require('@/assets/img/company.png')
                  : user.profilePhoto ?? require('@/assets/img/user.jpg')
              "
              alt="avatar"
            />
            <c-flex
              v-else
              justify="center"
              align="center"
              w="60px"
              h="60px"
              rounded="lg"
              :bg="getInitialsColor(user.firstname)"
            >
              <c-text fontSize="xl" fontWeight="500">{{
                getFirstLetterName(user.firstname, user.lastname)
              }}</c-text>
            </c-flex>
            <c-flex flexDirection="column" justify="center" ml="10px">
              <c-flex align="center">
                <c-text cursor="pointer" fontWeight="600" fontSize="lg" mr="2">
                  <template v-if="postAs">
                    {{ activeCompany.name }}
                  </template>
                  <template v-else>
                    {{ user.firstname }} {{ user.lastname }}
                  </template>
                </c-text>
                <c-text>{{ feelingText }}</c-text>
              </c-flex>
              <c-box w="100px" fontWeight="500">
                <PostMenu
                  fill-color="#FBCDBE"
                  :searchable="false"
                  :full-width="true"
                  size="md"
                  :reduce="(scope) => scope.value"
                  :options="postScopes"
                  label="key"
                  v-model="post.postType"
                />
              </c-box>
            </c-flex>
          </c-flex>

          <c-box maxH="230px" mb="3" overflowY="auto">
            <quill-editor
              class="editor"
              ref="textEditor"
              v-model="post.content"
              @change="onChange"
              :options="editorOptions"
            />
          </c-box>

          <c-flex align="center" justify="space-between" mt="auto" mb="4">
            <c-button
              variant="outline"
              size="sm"
              variant-color="vc-orange"
              class="hover_btn"
              @click="openAISuggestion"
            >
              <i class="far fa-lightbulb mr-2"></i> Suggest with AI
            </c-button>
            <c-menu>
              <c-menu-button size="xs" variant="link">
                <i class="fas fa-smile"></i>
              </c-menu-button>
              <c-menu-list>
                <VEmojiPicker @select="selectEmoji" />
              </c-menu-list>
            </c-menu>
          </c-flex>
          <c-flex justify="space-between" class="event_div" v-if="savedEvent">
            <div style="position: relative">
              <c-text style="color: orangered" fontSize="xs"
                >{{ savedEvent.start_date }},
                {{ savedEvent.start_time }}</c-text
              >
              <h3>{{ savedEvent.name }}</h3>
              <c-text fontSize="sm">{{ savedEvent.company }}</c-text>
              <c-text fontSize="xs">{{
                savedEvent.type === 'VIRTUAL' ? 'Online' : 'In person'
              }}</c-text>
            </div>
            <c-flex justify="space-between">
              <c-icon-button
                @click="openCreateEventModal"
                size="xs"
                style="position: absolute; bottom: 3px"
                aria-label="close div"
                icon="pen"
              />
              <c-icon-button
                size="xs"
                style="position: absolute; top: 3px; margin-left: 2px"
                @click="removeEvent"
                variant-color="red"
                aria-label="close div"
                icon="close"
              />
            </c-flex>
          </c-flex>

          <c-flex
            align="center"
            justify="space-between"
            bg="gray.100"
            h="54px"
            mt="auto"
            borderRadius="sm"
            px="4"
          >
            <c-text fontSize="sm" fontWeight="600">Add to your post</c-text>
            <c-flex>
              <input
                @change="onFileUpload"
                ref="fileInput"
                type="file"
                hidden
                name=""
                id=""
                :accept="fileUploadType"
              />
              <c-button
                px="0"
                mr="3"
                :variant-color="
                  this.fileType === PostAddonType.media ? 'gray' : 'white'
                "
                display="flex"
                alignItems="center"
                justifyContent="center"
                :disabled="isCreatingPost"
                @click="selectPostAddonType(PostAddonType.media)"
              >
                <c-icon color="vc-green.500" size="5" name="images" />
              </c-button>
              <c-button
                px="0"
                mr="3"
                :variant-color="
                  this.fileType === PostAddonType.event ? 'gray' : 'white'
                "
                display="flex"
                alignItems="center"
                :disabled="isCreatingPost"
                justifyContent="center"
                @click="openCreateEventModal"
              >
                <c-icon color="vc-info.500" size="5" name="calendar" />
              </c-button>
              <c-button
                px="0"
                mr="3"
                :variant-color="
                  this.fileType === PostAddonType.document ? 'gray' : 'white'
                "
                display="flex"
                alignItems="center"
                :disabled="isCreatingPost"
                justifyContent="center"
                @click="selectFile(PostAddonType.document)"
              >
                <c-icon color="vc-orange.500" name="link" size="5" />
              </c-button>
              <c-button
                px="0"
                mr="3"
                :variant-color="
                  this.fileType === PostAddonType.feeling ? 'gray' : 'white'
                "
                display="flex"
                alignItems="center"
                :disabled="isCreatingPost"
                justifyContent="center"
                @click="selectFile(PostAddonType.feeling)"
              >
                <c-icon color="vc-orange.500" name="smile" size="5" />
              </c-button>
              <c-button
                px="0"
                mr="3"
                :variant-color="
                  this.fileType === PostAddonType.user &&
                  taggedSelectedUsers.length === 0
                    ? 'gray'
                    : taggedSelectedUsers.length > 0
                    ? 'orange'
                    : 'white'
                "
                display="flex"
                alignItems="center"
                :disabled="isCreatingPost"
                justifyContent="center"
                @click.prevent="
                  closePostModal;
                  selectFile(PostAddonType.user);
                  showAllUsersModal = true;
                "
              >
                <c-icon color="vc-blue.700" name="user" size="5" />
              </c-button>
              <c-button
                px="0"
                :variant-color="
                  this.fileType === PostAddonType.company &&
                  taggedSelectedCompanies.length === 0
                    ? 'gray'
                    : taggedSelectedCompanies.length > 0
                    ? 'orange'
                    : 'white'
                "
                display="flex"
                alignItems="center"
                :disabled="isCreatingPost"
                justifyContent="center"
                @click.prevent="
                  closePostModal;
                  selectFile(PostAddonType.company);
                  showAllCompaniesModal = true;
                "
              >
                <c-icon color="vc-orange.300" name="users" size="5" />
              </c-button>
            </c-flex>
          </c-flex>
          <c-flex
            v-if="fileType === PostAddonType.media && !uploadedFiles.length"
            borderRadius="lg"
            flexDirection="column"
            align="center"
            justify="center"
            w="full"
            mt="10px"
            height="210px"
            bg="gray.100"
          >
            <c-button @click="addMedia" h="full" w="full" class="flex flex-col">
              <c-icon color="gray.500" name="images" size="12" />
              <c-text color="gray.500" fonSize="20px" fontWeight="600" ml="3px"
                >Add Photos/Videos</c-text
              >
            </c-button>
          </c-flex>
          <c-flex
            v-if="fileType === PostAddonType.document && !uploadedFiles.length"
            borderRadius="lg"
            flexDirection="column"
            align="center"
            justify="center"
            w="full"
            mt="10px"
            height="210px"
            bg="gray.100"
          >
            <c-button
              @click="addDocument"
              h="full"
              w="full"
              class="flex flex-col"
            >
              <c-icon color="gray.500" name="images" size="12" />
              <c-text color="gray.500" fonSize="20px" fontWeight="600" ml="3px"
                >Add Document</c-text
              >
            </c-button>
          </c-flex>
          <c-flex
            v-if="fileType === PostAddonType.feeling"
            style="overflow-y: scroll"
            borderRadius="lg"
            flexDirection="column"
            align="center"
            justify="center"
            w="full"
            class="py-2"
            mt="10px"
            height="210px"
            bg="gray.100"
          >
            <feeling-emoji-view @feelingSelected="handleFeeling" />
          </c-flex>
          <div v-if="uploadedFiles.length" class="grid-view">
            <c-button
              size="sm"
              variant-color="orange"
              @click="addFile"
              position="absolute"
              zIndex="1"
              left="6"
              bottom="5"
              >Add File</c-button
            >
            <c-button
              @click="clearUploadedFiles"
              right="6"
              bottom="5"
              size="sm"
              zIndex="1"
              variant-color="orange"
              aria-label="close view"
              position="absolute"
              icon="close"
            >
              Clear all
            </c-button>
            <div
              v-for="(file, index) in uploadedFiles"
              :key="file.id"
              class="grid-item mt-0"
            >
              <c-spinner v-if="uploadingFiles[file.id]" color="vc-blue.500" />
              <template v-else-if="file.type.startsWith('application/pdf')">
                <c-icon color="red.500" size="12" name="file-pdf" />
                <span class="file-name">{{ file.name }}</span>
              </template>
              <template
                v-else-if="
                  file.type.startsWith(
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  )
                "
              >
                <c-icon color="vc-blue.500" size="12" name="file-word" />
                <span class="file-name">{{ file.name }}</span>
              </template>
              <template
                v-else-if="file.type.startsWith('application/vnd.ms-excel')"
              >
                <c-icon color="vc-green.500" size="12" name="file-excel" />
                <span class="file-name">{{ file.name }}</span>
              </template>
              <template
                v-else-if="
                  file.type.startsWith(
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  )
                "
              >
                <c-icon color="vc-green.500" size="12" name="file-excel" />
                <span class="file-name">{{ file.name }}</span>
              </template>
              <template v-else-if="file.type.startsWith('text/csv')">
                <c-icon color="vc-green.500" size="12" name="file-excel" />
                <span class="file-name">{{ file.name }}</span>
              </template>
              <template v-if="file.type.startsWith('image')">
                <div
                  :style="{
                    backgroundImage: `url(${file.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height:
                      getAttachments().length > 1 &&
                      file.type.startsWith('image')
                        ? '200px'
                        : '300px',
                    position: 'relative'
                  }"
                >
<!--                  <img height="100%" :src="file.url" alt="Uploaded Image" />-->
                  <c-icon-button
                      @click="removeAttachedFile(index)"
                      aria-label="but"
                      size="sm"
                      style="position: absolute; bottom: 20px; right: 20px"
                      icon="close"
                      color="tomato"
                  />
                </div>
              </template>
              <template v-if="file.type.startsWith('video')">
                <video controls :src="file.url"></video>
              </template>
            </div>
          </div>
        </c-modal-body>
        <c-modal-footer pt="0">
          <c-button
            v-if="isEditingPost"
            :is-disabled="post.content.length === 0 || isCreatingPost"
            :is-loading="isCreatingPost"
            variant-color="vc-orange"
            h="49px"
            w="full"
            mb="10px"
            @click="onUpdatePostClicked"
          >
            Update Post
            <c-spinner
              v-if="isCreatingPost"
              color="vc-orange.400"
              thickness="2px"
            />
          </c-button>
          <c-button
            v-else
            :is-disabled="isContentEmpty || isCreatingPost"
            :is-loading="isCreatingPost"
            variant-color="vc-orange"
            h="49px"
            w="full"
            mb="10px"
            @click="onPostClick"
          >
            Post
            <c-spinner
              v-if="isCreatingPost"
              color="vc-orange.400"
              thickness="2px"
            />
          </c-button>
          <!-- <c-button @click="closePostModal">Cancel</c-button> -->
        </c-modal-footer>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>

    <OpenAIModal
      title="Post Suggestion"
      :context="aiSuggestValue"
      :isOpen="isAiModalOpen"
      :noBullets="true"
      @close="closeAiModal"
      v-if="isAiModalOpen"
    />
  </c-box>
</template>

<script>
import { Quill } from 'vue-quill-editor';
import 'quill-mention/dist/quill.mention.min.css';
import 'quill-mention';
import '@/helpers/editor/blots/mention';
import 'quill-emoji/dist/quill-emoji.css';
import OpenAIModal from '@/views/App/components/OpenAiModal.vue';

import {
  createPost, editPost,
  getMatchingTags,
  getMatchingUsers,
} from '@/services/insight';

import { mapGetters, mapState } from 'vuex';
import '../../../../helpers/utils.js';

import { VEmojiPicker } from 'v-emoji-picker';
import { uploadFile } from '@/services/common';
import { generateID } from '@/helpers/data';
import { PostAddonType, PostScope } from '@/helpers/enums';
import PostMenu from '@/views/App/Explore/components/PostMenu.vue';
import FeelingEmojiView from '@/views/App/Explore/components/FeelingEmojiView.vue';
import * as Emoji from 'quill-emoji';
import { EventBus } from '@/eventBus';
import { createEventPost } from '@/services/event';
import { getInitials } from '../../../../helpers/utils';
import placeHolderImage from '@/assets/img/company.png';
import TaggedUsersModal from '@/views/App/Explore/components/tagged/TaggedUsersModal.vue';
import TaggedCompaniesModal from '@/views/App/Explore/components/tagged/TaggedCompaniesModal.vue';

Quill.register('modules/emoji', Emoji);

export default {
  components: {
    TaggedUsersModal,
    TaggedCompaniesModal,
    FeelingEmojiView,
    PostMenu,
    OpenAIModal,
    VEmojiPicker,
  },
  data() {
    return {
      isEditingPost: false,
      aiSuggestValue: '',
      isAiModalOpen: false,
      placeHolderImage,
      showAllUsersModal: false,
      showAllCompaniesModal: false,
      showEmojiPicker: false,
      selectedUsers: [],
      isFeeling: false,
      isCompany: false,
      isPerson: false,
      isDocument: false,
      isVideo: false,
      isImage: false,
      postScopes: [
        {
          key: 'public',
          value: 'PUBLIC',
        },
        {
          key: 'private',
          value: 'PRIVATE',
        },
        {
          key: 'company',
          value: 'LIMITED',
        },
      ],
      post: {
        id: null,
        content: '',
        postType: 'PUBLIC',
        isEdited: false
      },
      isPostModalOpen: false,
      feelingText: null,
      fileType: null,
      editorOptions: {
        placeholder: `What is on your mind, ${this.showUserName()}?`,
        cursor: 'end',
        modules: {
          toolbar: false,
          'emoji-textarea': false,
          'emoji-shortname': false,
          clipboard: {
            matchers: [[Node.TEXT_NODE, this.linkMatcher]],
          },
          mention: {
            maxChars: 31,
            isolateCharacter: true,
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@', '#'],
            blotName: 'vc-mention',
            source: async (searchTerm, renderList, mentionChar) => {
              try {
                let matches = [];
                if (mentionChar === '@') {
                  matches = await this.getUserMatches(searchTerm);
                } else {
                  matches = await this.getTagMatches(searchTerm);
                }
                renderList(matches, searchTerm);
              } catch (e) {
                console.log({ e });
                renderList([], searchTerm);
              }
            },
          },
        },
        theme: 'snow',
      },
      fileUploadType: 'image/*',
      uploadedImages: [],
      uploadedVideos: [],
      uploadedFiles: [],
      uploadingFiles: {},
      uploadedDocuments: [],
      isContentEmpty: true,
      isCreatingPost: false,
      showEventForm: false,
      postAs: null,
      users: [],
      search: '',
    };
  },
  computed: {
    ...mapGetters('event', {
      savedEvent: 'getSavedEvent',
    }),
    ...mapGetters('explore', {
      taggedSelectedUsers: 'getSelectedTaggedUsers',
      taggedSelectedCompanies: 'getSelectedTaggedCompanies',
    }),
    PostScope() {
      return PostScope;
    },
    selectedPostAddonType() {
      return this.$store.getters['explore/getPostAddonType'];
    },
    PostAddonType() {
      return PostAddonType;
    },
    ...mapState('auth', {
      user: (state) => state.user,
    }),
    ...mapState('company', {
      activeCompany: (state) => state.activeCompany,
    }),
    ...mapGetters('company', ['isCompanyAdmin']),
    editor() {
      return this.$refs.textEditor.quill;
    },
  },
  mounted() {
    this.focusEditor();
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    editedPost: {
      type: Object,
      default: null,
    },
  },
  watch: {
    openModal(newVal, oldVal){
      console.log(newVal);
      console.log(oldVal)
    }
  },
  methods: {
    removeAttachedFile(index){
      this.uploadedFiles.splice(index, 1)
      this.$refs.fileInput.value = null;
    },
    onUpdatePostClicked(){
      this.isCreatingPost = true
      editPost({ postId: this.post.id, updatedContent: this.post.content })
        .then(() => {
          EventBus.$emit('edit-status', {
            ...this.post,
            isEdited: true,
          });
          this.post.isEdited = true;
          this.isCreatingPost = false;
          this.closePostModal();
          this.$toast({
            title: 'You have successfully updated this post.',
            description: `This post has been successfully updated`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        })
        .catch((err) => {
          console.log(err);
          this.isCreatingPost = false;
          this.closePostModal();
          this.$toast({
            title: 'An error occurred.',
            description: `Error while uploading file, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
      });
    },
    getInitials,
    clearUploadedFiles() {
      this.uploadedFiles = [];
    },
    focusEditor() {
      // Access the Quill instance
      const quill = this.$refs.textEditor.quill;
      // Focus the editor
      quill.focus();
    },
    removeEvent() {
      this.$store.dispatch('event/removeEvent');
    },
    openCreateEventModal() {
      this.selectPostAddonType('event');
      EventBus.$emit('open-event-edit-modal', this.savedEvent);
      this.closePostModal();
    },
    moveToEventHome() {
      this.$router.push({ name: 'EventDetails', params: { id: 1 } });
    },
    removeItem(itm) {
      this.selectedUsers = this.selectedUsers.filter(
        (item) => item.id !== itm.id
      );
    },
    addToSelectedUsers(item) {
      console.log('Fixing', item);
      this.selectedUsers = this.selectedUsers.concat(item);
    },
    selectPostAddonType(selected) {
      console.log('Thugs***', selected);
      this.selectFile(selected);
      this.$store.dispatch('explore/selectPostAddonType', selected);
    },
    selectFile(type) {
      this.fileType = type;
    },
    openFileDialog() {
      this.isDocument = true;
      this.openPostModal();
    },

    handleFeeling(feel) {
      if (feel !== null) {
        this.feelingText = `is ${feel.code} feeling ${feel.text}`;
      } else {
        this.feelingText = null;
      }
    },
    openPersonDialog() {
      this.isPerson = true;
    },
    openBusinessDialog() {
      this.isCompany = true;
    },
    openFeelingDialog() {
      this.openPostModal();
    },
    getInitialsColor(name) {
      const hash = this.hashCode(name);
      return `hsl(${hash % 360}, 60%, 50%)`; // Example using hue based on hash
    },
    showUserName() {
      return this.$store.state.auth.user.firstname;
    },
    hashCode(string) {
      let hash = 0;
      for (let i = 0; i < string.length; i++) {
        hash += string.charCodeAt(i);
      }
      return hash % 101; // Use a prime number for better distribution
    },
    getFirstLetterName(firstname, lastname) {
      return `${firstname.charAt(0).toUpperCase()}${lastname
        .charAt(0)
        .toUpperCase()}`;
    },
    setContentEmpty() {
      this.isContentEmpty = !this.editor.getText().trim();
    },
    linkMatcher(node, delta) {
      const regex = /https?:\/\/[^\s]+/g;
      if (typeof node.data !== 'string') return;
      const matches = node.data.match(regex);

      if (matches && matches.length > 0) {
        const ops = [];
        let str = node.data;
        matches.forEach(function (match) {
          const split = str.split(match);
          const beforeLink = split.shift();
          ops.push({ insert: beforeLink });
          ops.push({ insert: match, attributes: { link: match } });
          str = split.join(match);
        });
        ops.push({ insert: str });
        delta.ops = ops;
      }

      return delta;
    },
    getTextBeforeCursor(cursorPosition) {
      const startPos = Math.max(
        0,
        cursorPosition - this.editorOptions.modules.mention.maxChars
      );
      const textBeforeCursorPos = this.editor.getText(
        startPos,
        cursorPosition - startPos
      );
      return textBeforeCursorPos;
    },
    onChange(e) {
      this.aiSuggestValue = e.text;
      const range = this.editor.getSelection();
      if (range == null) return;
      const cursorPosition = range.index;
      const textBeforeCursor = this.getTextBeforeCursor(cursorPosition);
      const indexOfHash = textBeforeCursor.lastIndexOf('#');
      const mentionCharPos =
        cursorPosition - (textBeforeCursor.length - indexOfHash);
      if (indexOfHash > -1) {
        this.renderHashTag({
          textBeforeCursor,
          indexOfHash,
          cursorPosition,
          mentionCharPos,
        });
      }
      this.setContentEmpty();
    },
    renderHashTag({
      textBeforeCursor,
      indexOfHash,
      cursorPosition,
      mentionCharPos,
    }) {
      if (!this.hasValidMentionCharIndex(indexOfHash, textBeforeCursor)) {
        return;
      }
      const textAfter = textBeforeCursor.substring(indexOfHash + 1);
      if (/\s$/.test(textBeforeCursor) && textAfter.trim()) {
        //   this.editor.getFormat()
        this.editor.deleteText(
          mentionCharPos,
          cursorPosition - mentionCharPos,
          Quill.sources.USER
        );
        this.editor.insertEmbed(
          mentionCharPos,
          this.editorOptions.modules.mention.blotName,
          { value: textAfter.trim(), denotationChar: '#' },
          Quill.sources.USER
        );
        this.editor.insertText(mentionCharPos + 1, ' ', Quill.sources.USER);
        this.editor.setSelection(mentionCharPos + 2, Quill.sources.USER);
      }
    },
    hasValidMentionCharIndex(mentionCharIndex, text) {
      if (mentionCharIndex > -1) {
        if (
          !(mentionCharIndex === 0 || !!text[mentionCharIndex - 1].match(/\s/g))
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    togglePostAs(as, closePopover) {
      closePopover();
      console.log('Posting as ==>', as);
      this.postAs = as;
    },
    openPostModal() {
      this.isPostModalOpen = true;
      EventBus.$emit('hideHeaderBackground', {status: true})
    },
    closePostModal() {
      this.isPostModalOpen = false;
      EventBus.$emit('hideHeaderBackground', {status: false})
      this.uploadedImages = [];
      this.uploadedVideos = [];
      this.content = '';
    },
    addImage() {
      this.fileUploadType = 'image';
      this.triggerFileUpload();
    },
    addVideo() {
      this.fileUploadType = 'video';
      this.triggerFileUpload();
    },
    addMedia() {
      this.fileUploadType = 'image/*,video/*';
      this.triggerFileUpload();
    },
    addFile() {
      this.triggerFileUpload();
    },
    addDocument() {
      this.fileUploadType =
        'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv';
      this.triggerFileUpload();
    },
    triggerFileUpload() {
      this.$nextTick().then(() => {
        this.$refs.fileInput.click();
      });
    },
    uploadFile({ file, type, id }) {
      var fileObject = {};
      let indexOfFile = undefined;
      // if (type === 'image/*,video/*') {
      fileObject = this.uploadedFiles.find((img) => img.id === id);
      indexOfFile = this.uploadedFiles.indexOf(fileObject);
      // } else {
      //   fileObject = this.uploadedVideos.find((video) => video.id === id);
      //   indexOfFile = this.uploadedVideos.indexOf(fileObject);
      // }
      console.log('files are here =>', this.uploadedFiles);
      fileObject.failed = false;
      fileObject.isUploading = true;
      uploadFile({ file, type })
        .then((res) => {
          fileObject.isUploading = false;
          fileObject.url = res.data.url;
          fileObject.uploaded = true;
          // if (type === 'image/*,video/*') {
          this.uploadedFiles.splice(indexOfFile, 1, fileObject);
          // } else {
          //   this.uploadedVideos.splice(indexOfFile, 1, fileObject);
          // }
        })
        .catch((e) => {
          console.log('Error==>', e);
          fileObject.isUploading = false;
          fileObject.failed = true;
          fileObject.file = file;
          // if (type === 'image/*,video/*') {
          this.uploadedFiles.splice(indexOfFile, 1, fileObject);
          // } else {
          //   this.uploadedVideos.splice(indexOfFile, 1, fileObject);
          // }
          this.$toast({
            title: 'An error occurred.',
            description: `Error while uploading file, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        });
    },
    async onFileUpload(e) {
      const selectedFiles = e.target.files;

      if (!selectedFiles.length) return;

      // Loop through all selected files
      for (const file of selectedFiles) {
        const id = generateID(4); // Generate unique ID
        const fileType = file.type; // No need for specific check here as MIME types are handled in input

        // Differentiate based on file type for display purposes (optional)
        let url = null;
        if (fileType.startsWith('image/')) {
          url = await this.blobToData(file); // Assuming blobToData converts to data URL
        }

        this.uploadedFiles.push({
          id,
          failed: false,
          type: fileType,
          uploaded: false,
          url, // Data URL for images only
          name: file.name, // Added file name for display
        });

        this.uploadFile({ file, type: fileType, id });
      }
    },

    blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async getUserMatches(searchTerm) {
      const res = await getMatchingUsers(`%${searchTerm}%`);
      const matches = res.data.user.map((user) => {
        return {
          id: user.id,
          value: `${user.firstname} ${user.lastname}`,
        };
      });
      return matches;
    },
    async getTagMatches(searchTerm) {
      const res = await getMatchingTags(`%${searchTerm}%`);
      const matches = res.data.tag.map((tag) => {
        return {
          id: tag.id,
          value: tag.name,
        };
      });
      return matches;
    },
    async onPostClick() {
      if (this.savedEvent) {
        console.log('Friisch', this.savedEvent);
        this.isCreatingPost = true;

        const p_data = {
          ...this.savedEvent,
          posts: {
            data: {
              companyId: this.activeCompany.id,
              content: this.post.content ?? this.content,
              post_feeling: this.feelingText,
              isCompanyPost: this.isCompany,
              postType: this.post.postType,
              mentions: {
                data: [
                  ...this.getUsersMentioned(),
                  ...this.getCompaniesMentioned(),
                ],
              },
            },
          },
        };

        try {
          const res = await createEventPost(p_data);
          this.$emit('postAdded', {
            ...res.data.insert_event_one.posts[0],
          });
          this.isCreatingPost = false;
          this.$store.dispatch('event/resetSelectedEventSpeakers');
          this.closePostModal();
          this.removeEvent();
          this.$toast({
            title: 'You have successfully added a post',
            description:
              'A post which includes an event has been successfully added',
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          await this.$store.dispatch('explore/resetTaggedCompany');
          await this.$store.dispatch('explore/resetTaggedUser');
        } catch (e) {
          this.isCreatingPost = false;
          this.$toast({
            title: 'Could not make post.',
            description: `${e.message}`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        this.isCreatingPost = true;
        const data = {
          attachments: this.getAttachments(),
          companyId: this.activeCompany.id,
          isCompanyPost: this.isCompany,
          post_feeling: this.feelingText,
          postType: this.post.postType,
          content: this.post.content,
          post_tags: { data: this.getPostTags() },
          mentions: {
            data: [
              ...this.getUsersMentioned(),
              ...this.getCompaniesMentioned(),
            ],
          },
        };
        if (this.postAs) {
          data['companyId'] = this.activeCompany.id;
        }
        try {
          const res = await createPost(data);
          this.$emit('postAdded', res.data.insert_post_one);
          this.isCreatingPost = false;
          this.closePostModal();
          this.$toast({
            title: 'You have successfully added a post',
            description: `A post have been successfully added`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
          this.uploadedFiles = [];
          await this.$store.dispatch('explore/resetTaggedCompany');
          await this.$store.dispatch('explore/resetTaggedUser');
        } catch (e) {
          this.isCreatingPost = false;
          this.$toast({
            title: 'An error occurred.',
            description: `Error while creating post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    getAttachments() {
      const files = this.uploadedFiles
        .filter((file) => file.uploaded)
        .map(({ url, type, id, name }) => {
          return {
            id,
            name,
            url,
            type,
          };
        });
      return [...files];
    },
    getPostTags() {
      const tags = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="#"]'
        )
      ).map((el) => el.dataset);

      return tags.map((tag) => {
        if (tag.id) {
          return {
            tagId: tag.id,
          };
        }
        return {
          tag: {
            data: {
              userId: this.user.id,
              name: tag.value,
            },
          },
        };
      });
    },
    getUsersMentioned() {
      return this.taggedSelectedUsers.map((mention) => {
        return {
          userId: mention.id,
        };
      });
    },

    getCompaniesMentioned() {
      return this.taggedSelectedCompanies.map((mention) => {
        return {
          companyId: mention.id,
        };
      });
    },

    getPostMentions() {
      const mentions = Array.from(
        this.$refs.textEditor.$el.querySelectorAll(
          '.mention[data-denotation-char="@"]'
        )
      ).map((el) => el.dataset);
      return mentions.map((mention) => {
        return {
          userId: mention.id,
        };
      });
    },
    openAISuggestion() {
      console.log(this.aiSuggestValue);
      this.isAiModalOpen = true;
    },
    closeAiModal(results) {
      // check if results is empty or null or undefined
      if (!results) {
        this.isAiModalOpen = false;
        return;
      } else {
        this.post.content = results;
        this.isAiModalOpen = false;
        // this.sentResults(results);
      }
    },
    selectEmoji(emoji) {
      this.content = this.content + emoji.data;
    },
  },
  created() {
    EventBus.$on('open-post-modal', (data) => {
      if (data) {
        this.isEditingPost = true;
        this.post = data;
      }
      this.openPostModal();
    });
    EventBus.$on('close-post-modal', this.closePostModal);
  },

  beforeDestroy() {
    EventBus.$off('open-post-modal', this.openPostModal);
    EventBus.$off('close-post-modal', this.closePostModal);
  },
};
</script>

<style lang="scss">
.grid-view {
  margin-top: 10px;
  border-radius: 8px;
  position: relative;
  background-color: rgba(216, 217, 220, 0.44);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px
}

.grid-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.event_div {
  height: 100px;
  background-color: rgba(197, 210, 224, 0.71);
  width: available;
  margin-bottom: 15px;
  border-radius: 8px;
  padding: 14px;
  position: relative;
}
.user_addon_post_search {
  width: 100%;
  background-color: white;
  padding: 10px;
  margin-top: 0;
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
}
.editor {
  flex: 1;
  ::v-deep {
    .ql-container {
      border-width: 0px;
      font-size: inherit;
      font-family: inherit;
      padding-right: 20px;
    }
    .mention {
      @apply font-bold bg-transparent;
    }
    .ql-mention-list {
      @apply overflow-y-scroll;
      max-height: 300px;
    }
  }

  .ql-container {
    font-size: 17px;
    height: 230px;

    #textarea-emoji {
      top: 36px;
      z-index: 1;
    }
  }

  .ql-snow {
    border-width: 0px;
    border: none;
  }
}

.modal-contents {
  height: 600px;
  overflow: hidden;
  overflow-y: scroll;
}

.vue__time-picker input.display-time {
  border: 0;
  background-color: #c7d6f466;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns with equal width */
  grid-gap: 20px; /* Adjust the gap between grid items as needed */
}

.grid-item {
  width: 100%; /* Ensure grid items take up full width of their grid cell */
}

.form-labels {
  font-weight: 500;
  color: #002b49;
}
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: orangered;
}
#menu-zvX {
  z-index: 5;
}
svg.fa-face-smile {
  font-size: 18px;
  margin-left: 15px;
  color: rgb(245 146 13);
}
</style>
